/**
 *中文语言包
 */
const cn = {
  projectTitle: '船油管理系统',
  language: '语言切换',
  loading: '拼命加载中',
  closeCurrentTab: '关闭当前标签页',
  closeOtherTabs: '关闭其它标签页',
  closeAllTabs: '关闭全部标签页',
  closeLeftTabs: '关闭左侧标签页',
  closeRightTabs: '关闭右侧标签页',
  refreshCurrentTab: '刷新当前标签页',
  close: '关闭',
  changePsw: '修改密码',
  changeEmail: '修改邮箱',
  signOut: '退出登录',
  personalCenter: '个人中心',
  noConfigureItems: '当前系统未配置项目， 请配置项目',
  failedToObtainProjectInfo: '获取项目信息失败',
  pleaseSelectSearchFiled: '请选择搜索字段！',
  pleaseEnterSearchContent: '请输入搜索内容！',
  sureToExit: '确定进行【退出】操作？',
  account: '账号',
  originalPsw: '原密码',
  newPsw: '新密码',
  confirmPaw: '确认密码',
  pswNotMatch: '确认密码与新密码不一致',
  originalPswNoEmpty: '原密码不能为空',
  newPswNoEmpty: '新密码不能为空',
  confirmPswNoEmpty: '确认密码不能为空',
  confirmPswInconsistentWithInputPsw: '确认密码与密码输入不一致',
  pswNoEmpty: '密码不能为空',
  pswLength: '密码长度须在6到12个字符',
  verifyCodeNoEmpty: '验证码不能为空',
  pswChangeSuccess: '密码修改成功，请重新登录',
  home: '首页',
  notFindAvailableTab: '未能找到可用标签页！',
  selectCurrentProject: '请选择当前项目',
  selectItem: '选择项目',
  add: '新增',
  add_2: '添加',
  overview: '总览',
  commonStatistics: '常用统计',
  customStatistics: '自定义统计',
  tableFieldConfiguration: '表字段配置',
  tableConfiguration: '表配置',
  addTable: '添加表',
  tableType: '表类型',
  status: '状态',
  assetFieldTemplate: '资产字段模板',
  addField: '添加字段',
  fieldName: '字段名称',
  type: '类型',
  sort: '排序',
  primaryKey: '主键',
  supportForQueries: '支持查询',
  operate: '操作',
  operate2: '操作',
  operateLower: '操作',
  edit: '编辑',
  delete: '删除',
  configurationOfAttachmentStructure: '附件结构配置',
  selectTableType: '选择表类型',
  tableName: '表名称',
  cancel: '取消',
  confirm: '确定',
  fieldType: '字段类型',
  yes: '是',
  no: '否',
  support: '支持',
  updateHistory: '更新历史',
  nonsupport: '不支持',
  fixedOnTheTableHead: '固定在表头',
  fieldNameCannotBeEmpty: '字段名称不能为空',
  typePlaceholder: '类型不能为空',
  sortAsNumericValues: '排序为数字值',
  addAttachmentTemplateNode: '新增附件模板节点',
  attachmentType: '附件类型',
  parentNode: '父节点',
  operationSuccess: '操作成功',
  deleteTip_1: '该节点存在子节点， 请先删除子节点',
  deleteTip: '确定删除数据吗？',
  disabledTip: '确定禁用数据吗？',
  enableTip: '确定启用数据吗？',
  oKToDelete: '确定进行删除？',
  lockUserTip: '锁定用户后，此用户将无权限登录系统，是否继续？',
  unlockUserTio: '解锁用户后，此用户将恢复相应权限，是否继续？',
  tip: '提示',
  attachmentTemplateDeletedSuccess: '删除附件模板成功',
  addedFieldSuccess: '增加字段成功',
  addTableFieldSuccess: '增加表字段成功',
  fieldModifiedSuccess: '修改字段成功',
  modifyTableSuccess: '修改表成功',
  fieldDeletedSuccess: '删除字段成功',
  tableDeleteSuccess: '删除表成功',
  addTableTypeSuccess: '增加表类型成功',
  deleteTableTypeSuccess: '删除表类型成功',
  noData: '暂无数据',
  noDataAlt: '暂无数据哦～',
  refresh: '刷新',
  query: '查询',
  search: '查询',
  searchOrder: '查询',
  exportExcel: '导出excel',
  batchDeletion: '批量删除',
  serialNum: '序号',
  filename: '文件名',
  bizId: '文件业务ID',
  bizType: '文件业务类型',
  invoiceCode: '发票代码',
  invoiceNum: '发票号码',
  VATInvoiceType: '增值税发票类型',
  buyerName: '购买方名称',
  sellerName: '销售方名称',
  totalPriceAndTax: '价税合计',
  tax: '税额',
  amount: '金额',
  invoiceDate: '开票日期',
  verificationTime: '核验时间',
  verificationMode: '核验方式',
  remark: '备注',
  view: '查看',
  onlineInspect: '在线查验',
  serviceName: '服务名称',
  moreQuery: '更多查询',
  generalQuery: '普通查询',
  selectDate: '选择日期',
  to: '至',
  startDate: '开始日期',
  endDate: '结束日期',
  advancedQuery: '高级查询',
  selectQueryField: '选择查询字段',
  operator: '计算符',
  value: '值',
  and: '并且',
  addCondition: '添加条件',
  reset: '重置',
  or: '或者',
  equal: '等于',
  greaterThan: '大于',
  lessThan: '小于',
  notEqual: '不等于',
  includedIn: '包含于',
  inquiryInfoIncomplete: '查询信息填写不完整！',
  invoicePreview: '发票预览',
  invoiceType: '发票类型',
  submit: '提交',
  submitting: '提交中...',
  enterValidPageTip: '请输入合法的页码',
  notSupportPreviewTip: '暂不支持此类型的文件预览，请直接下载！',
  failedGetAttachmentTip: '获取附件失败，请稍后重试！',
  inspectionResult: '查验结果',
  true: '真',
  pleaseSelectAssetTable: '请选择资产表',
  selectAssetTable: '选择资产表',
  cityAssetMap: '城市资产图',
  projectCompanyRegionalDistributionMap: '项目公司区域分布图',
  ARTARank: '项目公司应收账款转让金额及发票金额排行',
  commonStatisticalClassification: '常用统计分类',
  dataResults: '数据结果',
  groupType: '分组类型',
  statisticField: '统计字段',
  statisticType: '统计类型',
  viewChart: '查看图表',
  totalAssets: '资产总数',
  approvedTotalOutputVal: '审批产值合计',
  totalInvoiceAmount: '发票金额合计',
  totalAccountsReceivableTransferAmount: '应收账款转让金额合计',
  assetsSubmittedForReview: '资产已提交审核数',
  unauditedAssets: '资产未审核数',
  approvedNum: '审核通过数',
  notApprovedNum: '审核未通过数',
  financingAmountAndProportion: '项目公司融资金额及占比',
  projectCompany: '项目公司',
  financingAmount: '融资金额',
  proportion: '占比',
  projectCompanyInvoiceAmount: '项目公司发票金额',
  invoiceAmount: '发票金额',
  projectCompanyFactoringContractAmount: '项目公司保理合同金额',
  factoringContractAmount: '保理合同金额',
  receivableTransferAmount: '应收账款转让金额',
  addTableType: '添加表类型',
  tableFieldTemplate: '表字段模板',
  fieldCode: '字段编码',
  fieldCode_2: '字段代码',
  code: '编码',
  code_2: '代码',
  tableIdentificationCode: '表识别码， 如合同表为CONTRACT',
  tableNameCannotBeEmpty: '表名称不能为空',
  codeCannotBeEmpty: '编码不能为空',
  username: '用户名',
  email: '邮箱',
  oldEmail: '旧邮箱',
  newEmail: '新邮箱',
  mobile: '手机号',
  disabled: '禁用',
  normal: '正常',
  createTime: '创建时间',
  updateTime: '更新时间',
  modify: '修改',
  loginAccount: '登录帐号',
  login: '登 录',
  password: '密码',
  verificationCode: '验证码',
  rememberAccount: '记住账号',
  forgetPassword: '忘记密码',
  role: '角色',
  emailFormatError: '邮箱格式错误',
  mobileFormatError: '手机号格式错误',
  usernameCannotBeEmpty: '用户名不能为空',
  emailCannotBeEmpty: '邮箱不能为空',
  mobileCannotBeEmpty: '手机号不能为空',
  makeSureTo: '确定对',
  perform: '进行',
  roleName: '角色名称',
  roleCode: '角色代码',
  roleType: '角色类型',
  authorize: '授权',
  roleNameCannotBeEmpty: '角色名称不能为空',
  name: '名称',
  nameLower: '名称',
  superiorMenu: '上级菜单',
  icon: '图标',
  menu: '菜单',
  menuName: '菜单名称',
  routePath: '路由地址',
  componentPath: '组件地址',
  englishName: '菜单英文名',
  enable: '启用',
  lock: '锁定',
  unlock: '解锁',
  button: '按钮',
  catalog: '目录',
  sortNum: '排序号',
  menuUrl: '菜单URL',
  authorizationMark: '授权标识',
  selectSuperiorMenu: '点击选择上级菜单',
  menuRouter: '菜单路由',
  menuIcon: '菜单图标',
  multipleCommas: '多个用逗号分隔, 如: user:list,user:create',
  menuIconName: '菜单图标名称',
  menuUrlCannotBeEmpty: '菜单URL不能为空',
  superiorMenuCannotBeEmpty: '上级菜单不能为空',
  submitReview: '提交审核',
  batchUpload: '批量上传',
  filerColumn: '筛选列',
  queryByCondition: '按条件查询',
  clearCondition: '清空条件',
  submitQuery: '提交查询',
  auditStatus: '审核状态',
  builtSystemOrg: '内置的系统组织',
  approved: '审核通过',
  approveFailed: '未通过',
  approveFailed_2: '审核未通过',
  initialState: '初始状态',
  underReview: '审核中',
  modified: '已修改',
  annex: '附件',
  annexName: '附件名',
  invoice: '发票',
  failedAuditResults: '未通过审核结果',
  detail: '详情',
  reviewTime: '审核时间',
  notBeConfigured: '该表单尚未配置表结构， 请到【表字段配置】中配置',
  selectDataToBeDeleted: '请选择待删除的数据',
  selectDataToBeReview: '请选择待提交审核的数据',
  fillCorrectValue: '请填写正确的值',
  uploadExcelDataFile: '上传Excel数据文件',
  dragFile: '将文件拖到此处，或<em>点击上传</em>',
  excelOnly: '只支持excel！',
  zipOnly: '只支持zip！',
  onlyExcelOnlyCanUpload: '只能上传Excel类型的文件',
  onlyImageCanUpload: '提示：只能上传jpg/png图片文件',
  cannotExceed: '文件大小不能超过',
  failedToImportData: '导入数据失败， 成功0条， 详情请查看【上传历史】',
  partDataImportedSuccess: '部分数据导入成功，',
  total: '共有数据',
  successItem: '成功',
  item: '条',
  checkDetail: '详情请查看【上传历史】',
  allImportedSuccess: '数据导入成功，',
  noFailed: '失败0条。',
  queryQualifiedAsset: '按照条件查询合格资产',
  selectField: '选择字段',
  valueFuzzy: '值（模糊）',
  addQueryCondition: '新增查询条件',
  resetAll: '重置所有',
  queryConditionIncorrect: '查询条件设置有误， 请重新设置',
  excelUploadParseHistory: 'Excel上传/解析历史',
  parseRecord: '解析记录',
  uploadTime: '上传时间',
  dataNum: '数据条数',
  successNum: '成功条数',
  resultsDesc: '结果描述',
  dataAnalysisDetail: '数据解析详情(错误项)',
  dataRow: '数据行（Excel）',
  uploaded: '已上传',
  uploadedAndCanDelete: '提示：文件已上传，重新上传前请删除文件',
  annexList: '附件列表',
  viewAllAttachments: '查看所有此类型附件',
  Attachment: '附件',
  batchUploadAttachment: '批量上传附件',
  syncToAsset: '请选择同步至的资产表',
  correspondingAsset: '对应资产',
  fileSize: '文件大小',
  preview: '预览',
  print: '打印',
  notSupportedPrint: '此类型文件不支持在线打印',
  modifyFileName: '修改文件名',
  syncAttachment: '同步附件',
  clickToViewSynchronousAttachment: '点击查看待同步附件',
  templateType: '模板类型',
  quickSelectMatch: '快速选择匹配项',
  synchronizeSelectedAsset: '同步选中的资产',
  synchronizeAll: '同步全部',
  selectSyncAttachmentMatchCondition: '选择同步附件匹配条件',
  selectAssetUploadedOther_1: '如果选中资产上传过其他【',
  selectAssetUploadedOther_2: '】的附件将会被此次同步覆盖， 是否继续?',
  synchronizeAttachmentSuccess_1: '同步附件成功, 共同步了',
  synchronizeAttachmentSuccess_2: '条资产的',
  syncTo: '同步到',
  fileNameCannotBeEmpty: '文件名不能为空',
  fileNameModifyFail: '文件名修改失败',
  selectSyncAttachment: '请选择要同步的附件',
  deleteFileCannotBeRecovered: '删除后文件不可恢复',
  fileDeleteSuccess: '文件删除成功',
  fileDeleteFail: '文件删除失败',
  attachmentUpload: ' 附件上传',
  fileTypeNotAllowed: '不允许上传该类型文件',
  onlyZip: '只能上传zip类型的文件',
  uploadAttachmentType: '提示：只能上传pdf/word/excel文件，且不超过4M',
  uploadAttachmentPDF: '提示：只能上传pdf文件，且不超过12M',
  uploadAttachmentPic: '提示：只能上传png/jpg图片,且不超过4M',
  uploadSalesTitle: '请上传销售确认单',
  selectUploadType: '请选择要上传的文件类型',
  pageExpired: '页面已失效',
  uploadingZip: '正在上传Zip包',
  batchUploadSuccess: '附件批量上传成功',
  invoiceAssociation: '发票关联',
  associatedInvoice: '已关联发票',
  disassociate: '取消关联',
  invoicePlaceHolder: '输入发票号码后回车',
  unconnectedInvoice: '未关联发票',
  relate: '关联',
  matchBuyerAndSeller: '匹配购销方',
  atLeastOneInvoice: '请至少选择一条发票数据！',
  warn: '警告',
  relatedTip: '检查到您提交的发票中存在与本资产购销方不一致的情况,是否继续关联？',
  invoiceAssociationSuccess: '发票数据关联成功！',
  invoiceDisassociatedSuccess: '发票数据解除关联成功！',
  querySuccess_1: '数据查询成功，共发现',
  querySuccess_2: '条数据！',
  switchSuccess: '切换成功',
  totalAsset: '总计资产数',
  submitted: '已提交',
  toBeSubmitted: '待提交',
  submitSuccess: '提交数据成功',
  submitFail: '提交失败',
  payable: '应付方',
  receivable: '应收方',
  contractNo: '合同编号',
  failureReason: '失败原因',
  submitAgainTip: '点击失败序号，可再次提交数据。',
  dataSubmitCompleted: '数据提交完成',
  associationStatus: '关联状态',
  connected: '已关联',
  unlinked: '未关联',
  theme: '主题',
  groupCount: '分组计数',
  count: '计数',
  sum: '求和',
  average: '平均',
  min: '最小',
  max: '最大',
  discountRate: '折价率',
  loanAmount: '放款金额',
  assetsAudited: '资产已审核数',
  feedbackNum: '已反馈数',
  date: '日期',
  attachmentDownload: '附件下载',
  parameterName: '参数名',
  parameterValue: '参数值',
  cannotBeEmpty: '不能为空',
  userOperate: '用户操作',
  requestMethod: '请求方法',
  requestParams: '请求参数',
  executionTime: '执行时长(毫秒)',
  IPAddress: 'IP地址',
  noPageTip: '抱歉！您访问的页面<em>失联</em>啦 ...',
  duplicateRequestTip: '请勿重复操作！',
  requestError: '请求异常，请稍后重试或联系管理员',
  backPreviousPage: '返回上一页',
  enterHomePage: '进入首页',
  layoutSetting: '布局设置',
  navBarType: '导航条类型',
  sidebarSkin: '侧边栏皮肤',
  default: '缺省：',
  welcomeToLogin: '欢迎登录',
  welcome: '欢迎',
  messageBox: '消息盒子',
  desc: '描述',
  userList: '用户列表',
  roleList: '角色列表',
  selectUser: '请选择用户！',
  userRoleConfigSuccess: '用户角色配置成功！',
  auditPoint: '审核要点',
  auditPointLower: '审核要点',
  selectRole: '请选择角色！',
  privileges: '分配权限',
  permissionList: '权限列表',
  rolePermissionConfigSuccess: '角色权限配置成功！',
  assetClassification: '资产分类',
  auditPointsConfiguration: '审核要点配置',
  import: '导入',
  export: '导出',
  auditCategory: '审核类别',
  categoryManage: '类别管理',
  pleaseSelect: '请选择',
  selectCompanyTeam: '切换企业和团队',
  switchCompanySuccess: '企业和团队切换成功',
  pleaseEnterContent: '请输入内容',
  rejectBeEmpty: '此字段值是必需的',
  save: '保存',
  acknowledge: '已知并保存',
  auditCategoryManage: '审核类别管理',
  uploadFile: '上传文件',
  uploadImage: '上传图片',
  downloadTemplate: '下载模板',
  importingData: '正在导入数据...',
  batchImport: '批量导入',
  batchImportSuccess: '批量导入成功！',
  exportField: '导出字段',
  selectAssetTableFirst: '请先选择资产表',
  auditPointImportTemplate: '审核要点导入模板',
  tableFileSelect: '表格字段选择',
  assetData: '资产数据',
  assetTable: '资产表',
  push: '推送',
  auditOverview: '审核总览',
  pendingAudit: '待审核',
  auditNotPass: '审核不通过',
  auditInstruction: '审核说明',
  addAuditCategories: '【全部审核类别】',
  showAll: '显示全部',
  assetApproveEnterPool: '该资产已审核通过，建议入池!',
  assetUnderReview: '该资产正在审核中!',
  assetNotReview: '该资产暂未审核!',
  auditBeApproved: '该审核分类已审核通过!',
  auditCategoryNotReview: '该审核分类暂未审核!',
  keyPointFail: '未通过审核要点',
  overallAuditStatus: '总体审核状态',
  auditHistory: '审核历史',
  businessAttachmentDisplayArea: '业务单据附件层次展示区',
  businessAttachmentArea: '业务单据附件管理区',
  attachmentToThisResource: '本资源附件',
  download: '下载',
  operationType: '操作类型',
  operationResult: '操作结果',
  feedbackType: '反馈类型',
  instructions: '操作说明',
  operateMechanism: '操作机构',
  operatorP: '操作人',
  operationTime: '操作时间',
  audit: '审核',
  assetAudit: '资产审核',
  auditCategoryAndKeyPoints: '审核类别及要点',
  totalNum: '总数',
  audited: '已审核',
  unaudited: '未审核',
  unPassed: '未通过',
  more: '更多',
  collapseAll: '收起全部',
  expandAll: '展开全部',
  temporaryStorageData: '暂存数据',
  recoverData: '恢复数据',
  oneClickAudit: '一键过审',
  noAuditPoint: '暂无审核要点',
  enterDescription: '输入说明',
  switchAttachment: '切换附件',
  selectAttachment: '附件选择',
  current: '当前',
  switch: '切换',
  loginAgainAfterRoleChange: '你正在修改自己的权限，提交成功后将重新登录！',
  noNeedTemporary: '暂无审核数据，无需暂存哦！',
  canRestoreData: '审核数据暂存成功，可使用【恢复数据】按钮恢复！',
  recoverSuccess: '审核数据恢复成功！',
  noLocalAsset: '本地没有发现当前资产的审核数据哦！',
  unauditedPoint: '检测到还有未审核的要点！',
  unfilledAudit: '检测到还有未填写的审核说明！',
  submitAuditTip: '确定提交审核数据吗?',
  submitPermissionTip: '确定保存权限数据吗?',
  auditSubmitSuccess: '审核数据提交成功！',
  attachmentPreview: '附件预览',
  feedback: '已反馈',
  all: '全部',
  pushed: '已推送',
  unPushed: '未推送',
  selectAtLeastOneData: '请至少选择一条数据！',
  pushSuccess: '推送成功！',
  generatedNewAttachment: '已经生成新的附件压缩包，点击下载',
  dataTemplate: '数据模板',
  attachmentTemplate: '附件模板',
  uploadData: '上传数据',
  uploadAttachment: '上传附件',
  uploadHistory: '上传历史',
  columnDisplaySetting: '列显示设置',
  attachment: '附件',
  pleaseEnterCategoryC: '请输入类别内容',
  addCategory: '添加类别',
  enterAuditCategoryFirst: '请先输入审核类别！',
  selectSynchronizedAsset: '选择待同步资产，附件类型：',
  upload: '上传',
  selectPackagedAsset: '选择打包资产',
  statusDesc: '状态描述',
  fieldNamePlaceholder: '字段名称,如合同名称',
  fieldCodePlaceholder: '字段编码, 如合同名称对应CONTRACT_NAME或HETONGMINGCHENG',
  fieldCodePlaceholder_2: '如:contract_name（id、parent_id、sys_stamp为系统保留字段，请勿使用）',
  pushStatus: '推送状态',
  underReview_2: '正在审核',
  dataModifySuccess: '数据修改成功！',
  dataAddSuccess: '数据添加成功！',
  atLeastSelectOneData: '请至少选择一条需要删除的数据！',
  deletePermanent: '此操作将永久删除数据, 是否继续?',
  dataDeleteSuccess: '数据删除成功！',
  dataDisabledSuccess: '数据禁用成功！',
  dataEnableSuccess: '数据启用成功！',
  currencyUnit: '万',
  selectAsset: '请先选择要查看的资产',
  name_2: '名称',
  totalNum_2: '合计',
  applyFinancingInstitution: '申请融资机构',
  financeApplicationNo: '融资申请编号',
  totalAmountTrade: '贸易总金额',
  intentionFinanceAmount: '意向融资金额',
  intentionFinanceRate: '意向融资年利率(%)',
  financePeriod: '融资账期(天)',
  financeInterest: '融资利息',
  financeApplicationDeadline: '融资申请有效截止日',
  factoringType: '保理类型',
  openInsurance: '明保',
  secretInsurance: '暗保',
  preReview: '初审',
  review: '复审',
  allFinancingList: '全部融资清单',
  noFeedback: '未反馈',
  feedbackNotConfirmed: '已反馈未确认',
  feedbackConfirmed: '已反馈已确认',
  submitForReview: '已提交审核',
  AuthenticationCenterUser: '认证中心用户',
  addUsername: '添加用户名',
  tablePermissionList: '表权限列表',
  pleaseEnter: '请输入',
  saveSuccess: '数据保存成功',
  importSuccess: '导入成功',
  importFail: '导入失败',
  downloadXmlTemplate: '下载xml模板',
  downloadJsonTemplate: '下载json模板',
  createTable: '创建表',
  fieldAlias: '字段别名',
  unique: '是否唯一',
  showDateOnly: '只显示日期',
  required: '是否必填',
  calculationFormula: '计算公式',
  sourceField: '源字段',
  mainTable: '主表',
  childTable: '子表',
  relateSourceField: '关联源字段',
  selectedField: '选中字段',
  relatedField: '已关联字段',
  selectTargetForm: '选择目标表单',
  notRequired: '（非必填）',
  quickSelectField: '输入#号快速选择字段',
  formulaOperatorTip: 'Tips：公式运算符提示 ',
  sureToDisassociate: '确定取消关联？',
  disassociateSuccess: '取消关联成功',
  selectMainTable: '选择主表',
  associateChildTableNum: '已关联子表数量',
  associateChildTable: '关联子表',
  childTableList: '子表列表',
  selectChildTable: '选择子表',
  sourceDocument: '源单据',
  documentName: '单据名称',
  targetDocumentQty: '目标单据数量',
  upstreamDocumentQty: '上游单据数量',
  associateTargetDocument: '已关联目标单据',
  relateTargetDocument: '关联目标单据',
  targetDocumentName: '目标单据名称',
  conversionField: '源单据与目标单据字段转换',
  sourceDocumentField: '源单据字段',
  targetDocumentField: '目标单据字段',
  sourceAssociateAndTargetList: '源单据与目标单据已关联字段清单',
  sourceDocumentFieldName: '源单据字段名称',
  targetDocumentFieldName: '目标单据字段名称',
  selectTargetDocument: '选择目标单据',
  sureToDisassociateAndDeleteData: '解除关联将同时删除数据关联，确定删除吗？',
  associateFail_1: '关联',
  associateFail_2: '失败，请在表字段配置中为该单据添加唯一字段。',
  viewName: '视图名称',
  boundViewsReportList: '已绑定视图的报表清单',
  reportName: '报表名称',
  remove: '移除',
  viewAndReportNameBound: '视图与报表名称绑定',
  addReportSuccess: '新增报表成功',
  sureToRemove: '确定进行移除？',
  removeReportSuccess: '移除报表成功',
  funded: '已融资',
  unfunded: '未融资',
  asset: '资产',
  financingStatus: '融资状态',
  back: '返回',
  auditResult: '审核结果',
  resultInterpretation: '结果说明',
  colorSchemes: '配色方案',
  organizationName: '机构名称',
  organizationCode: '组织代码',
  organization: '机构',
  department: '部门',
  principal: '负责人',
  superiorOrganization: '上级组织',
  functionName: '功能点名称',
  functionCode: '权限代码',
  functionList: '功能列表',
  superiorFunction: '上级功能',
  rootMenu: '顶级菜单',
  flowChart: '审批流程图',
  waitingTask: '待处理任务',
  taskId: '任务ID',
  currentProcess: '当前流程',
  taskDetails: '单据详情',
  approvalHistory: '审批历史',
  applyUpdate: '申请修改',
  updateAuditing: '申请修改审批中...',
  applyTip: '确定申请吗？',
  agree: '同意',
  disagree: '不同意',
  approver: '审批人',
  approverInstruction: '审批说明',
  approvalComments: '请输入审批意见',
  saveAndInitiateApproval: '保存并发起审批',
  saveAndInitiateApprovalSuccess: '发起审批成功,待下一流程审核人员处理',
  initiateApproval: '发起审批',
  dismissApproval: '已驳回，审批流程回到最初节点',
  approve: '审批通过，待下一流程审核人员处理',
  initiateApprovalTip: '确定发起审批吗？',
  businessData: '业务数据',
  businessId: '数据Id',
  tableDesc: '表描述',
  basicInformation: '基本信息',
  notApproval: '暂未开始审批流程',
  confirmContinue: '确定确认此单据吗？',
  checkTips: '检测到部分必填字段没有填写，请检查后重试',
  noApprovalHistory: '暂无审批历史',
  noCreateTime: '暂无创建时间',
  authCode: '授权码',
  getAuthCode: '获取授权码',
  sureAgree: '确定同意吗？',
  sureReject: '确定拒绝吗？',
  reject: '拒绝',
  rejectUpdate: '拒绝修改',
  agreeUpdate: '同意修改',
  agreed: '已同意',
  rejected: '已拒绝',
  agreeOrNot: '是否同意',
  processDefinitionKey: '流程KEY',
  previous: '上一步',
  next: '下一步',
  getCode: '获取验证码',
  passwordResetSuccessful: '密码重置成功',
  inputUserInfo: '输入用户信息',
  resetPassword: '重置密码',
  emailLogin: '邮箱登录',
  mobileLogin: '手机登录',
  noActiveCompany: '没有找到当前用户激活的企业，请联系管理员',
  noDefaultTeam: '没有找到默认的团队',
  hasUserTip: '提示：如果系统中已经存在相同的电子邮箱，那么此电子邮箱所对应的账号信息将被重置为当前提交的信息（包括密码）',
  authenticate: '编辑CA证书',
  confirmAuthenticate: '确定进行企业认证吗？',
  waitAuthenticateSuccess: '提交认证成功，请等待认证结果',
  pass: '通过',
  bunkerchainCa: 'Bunkerchain CA',
  netrustCa: 'Netrust CA',
  setCA: '设置CA',
  inputAuthenticationCode: '请输入CA证书',
  CASetSuccess: 'CA证书设置成功！',
  queryCondition: '查询条件',
  authenticationSuccess: '企业认证成功',
  ip: 'ip地址',
  title: '标题',
  requestUri: '请求地址',
  method: '请求方法',
  exception: '异常',
  operateDate: '操作时间',
  youAre: '你是平凡生活的英雄梦想，Lan Ning。',
  userId: '用户ID',
  noFlowForm: '出错啦，没有找到此流程对应的单据',
  flow: '流程',
  node: '节点',
  user: '用户',
  uploader: '上传者',
  acceptor: '接收方',
  bindingMariner: '绑定船员',
  onlyOneUseRightBtn: '若只绑定一位审批人，请使用右侧绑定按钮',
  blockChainInfo: '区块链信息',
  selectCompany: '请先选择公司',
  selectDepartment: '请先选择部门',
  selectTeam: '请先选择团队',
  selectOrder: '请先选择订单',
  refreshSuccess: '数据刷新成功',
  company: '企业',
  team: '团队',
  addTeam: '新增团队',
  editTeam: '编辑团队',
  deleteTeam: '删除团队',
  inputTeamName: '请输入团队名称',
  invite: '邀请',
  inviteUser: '邀请用户',
  inviteCompany: '邀请企业',
  inviteCompanyAdmin: '邀请企业系统管理员',
  inviteCompanyManager: '邀请企业业务管理员',
  inviteTeamManager: '邀请团队管理员',
  inviteTeamMember: '邀请团队成员',
  invitedMailSentSuccess: '邀请邮件发送成功',
  transfer: '转移',
  chooseTransferTeam: '选择转入团队',
  agreeUpdateCompany: '确定同意修改企业信息吗？',
  rejectReasonTip: '请输入拒绝理由',
  checkAll: '全选',
  startTransfer: '开始转移',
  transferTo: '转移到',
  transferredToOrFrom: '转移出/入'
}

const enterpriseRegister = {
  enterpriseRegister: '企业注册',
  companyInfo: '企业信息',
  companyBaseInfo: '企业基本信息',
  contactInfo: '联系人信息',
  adminInfo: '管理员信息',
  financerInfo: '出资人信息',
  companyDocument: '企业材料',
  enterpriseName: '企业名称',
  companyName: '企业名称',
  businessIncorporationNumber: 'UEN或企业注册号',
  incorporationCountry: '注册国家',
  streetName: '注册街道名',
  buildingName: '注册楼号',
  unitNumber: '注册门牌号',
  cityOrState: '城市/州',
  publicKey: '公共钥匙',
  apiKey: 'API钥匙',
  apiName: 'API钥匙名字',
  fax: '传真',
  companyType: '企业类型',
  designation: '职务',
  businessIncorporationDocument: '企业注册文件或ACRA',
  authorizationLetter: '非公司董事授权书',
  operatorLicences: 'MPA操作员执照',
  craftOperatorLicences: 'MPA工艺操作员执照',
  bdnLogo: 'BDN LOGO',
  otherDocument: '其它材料',
  firstName: '名',
  lastName: '姓氏',
  salutation: '昵称',
  homePhoneNumber: '家庭电话',
  officePhoneNumber: '办公电话',
  duplicatePassword: '确认密码',
  verificationCode: '验证码',
  clickVerifyEmail: '点击验证邮箱',
  codeObtainedSuccessfully: '验证码获取成功，请注意查看邮件',
  emailVerified: '邮箱已验证',
  readAndAgree: '已阅读并同意',
  register: '注册',
  acceptRegister: '接受注册',
  acceptRegisterTip: '确定接受注册吗',
  refuseRegister: '拒绝注册',
  existingAccount: '已有账号？',
  loginImmediately: '立即登录',
  verify: '校验',
  registrationAgreement: 'BUNKER CHAIN企业注册协议',
  inputEmail: '请输入邮箱地址',
  inputCode: '请输入验证码',
  verifyTipFront: '验证码已经发送到',
  verifyTipEnd: '，请查收后输入验证码校验',
  emailPassed: '邮箱验证通过',
  emailFailed: '邮箱验证未通过',
  readAndAgreeAgreement: '请先阅读并同意注册协议',
  registerSuccess: '企业管理员注册成功，请登录后完善企业信息',
  registerSuccess2: '注册成功',
  jump2login: '去登录',
  enterprisesInfo: '企业信息',
  group: '集团名称',
  incorporationNos: '企业注册编号',
  incorporationDate: '企业注册日期',
  address: '企业地址',
  city: '城市',
  state: '省',
  postal: '邮编',
  contactPerson: '企业联系人',
  position: '职位',
  email: '联系人邮箱',
  phone: '手机号',
  telephone: '手机号',
  enterpriseType: '企业类型',
  bargesCount: '驳船数量',
  vesselCount: '舶船数量',
  surveyorCount: '公证人数量',
  businessLicense: '营业执照',
  bankInfo: '银行信息',
  bankName: '银行名称',
  branch: '分行名称',
  account: '账号',
  accountType: '账号类型',
  ship: '船舶',
  vesselInfo: '舶船信息',
  owner: '船舶所有人',
  shipManager: '船舶管理员',
  charterer: '租船人',
  tankCount: '油管数量',
  tankRank: '油管级别',
  imo: 'IMO号',
  status: '状态',
  registryPort: '船籍港',
  callSign: '呼号',
  vesselName: '船名称',
  exName: '旧名',
  vesselType: '船舶类型',
  subClass: '船舶子类',
  licenceNo: '船舶执照号',
  cellPhone: '手机号码',
  locationLevel: '位置',
  mfmIpAddress: 'MFM IP 地址',
  location: '位置',
  satellitePhone: '卫星电话',
  buildYear: '建造年份',
  majorApproved: 'MAJOR APPROVED',
  shipClass: '船籍社',
  ownershipStatus: '所有权状态',
  subStatus: '租赁方式',
  shipContact: '联系与验证方式',
  hp: '联系电话',
  charterPartyEnd: '租赁结束日',
  marinerInfo: '船员信息',
  nationality: '国籍',
  identity: '验证方式',
  representCompany: '代表公司',
  attachmentName: '附件名称',
  enterAttachmentName: '请输入附件名称',
  uploadAttachmentTypeEnterprise: '只能上传pdf/jpg文件，且不超过4M',
  uploadSuccess: '附件上传成功',
  saveSuccessAndUpload: '数据保存成功，可继续完善附件',
  waitPerfect: '待完善',
  waitReview: '待审核',
  waitCertification: '待认证',
  certificationPass: '认证通过',
  certificationReject: '认证被拒绝',
  noState: '无状态',
  CARGO_SELLER: '供油方',
  BUNKER_TRADER: '贸易商',
  FINANCING_INSTITUTION: '金融机构',
  SHIP_OWNER: '货轮公司',
  SURVEYORS: '见证人',
  BUNKER_SUPPLIER: '驳船公司',
  chooseVesselFirst: '请先选择舶船',
  singapore: '新加坡',
  china: '中国',
  chinaTanwan: '中国（台湾）',
  chinaHongKong: '中国（香港）',
  chinaMacau: '中国（澳门）',
  otherState: '其他国家',
  duplicateCompanyTip: '系统已经存在相同的企业，不允许重复注册！',
  duplicateCompanyUENTip: '系统已经存在相同的企业UEN，不允许重复注册！',
  duplicateEmail: '系统已经存在相同的Email账号，继续注册将使用此Email账号对应的账号信息，是否继续？',
  bdnEmailTitle: '发送BDN的邮件标题'
}

const menus = {
  MY_ENTERPRISES: '我的企业',
  HOME: '首页',
  SYSTEM_MANAGEMENT: '系统管理',
  MENU_MANAGEMENT: '菜单管理',
  ORGANIZATION_MANAGEMENT: '组织管理',
  COMPANY_MANAGEMENT: '企业管理',
  DEPARTMENT_MANAGEMENT: '部门管理',
  TEAM_MANAGEMENT: '团队管理',
  ROLE_MANAGEMENT: '角色管理',
  USER_MANAGEMENT: '用户管理',
  FUNCTION_MANAGEMENT: '功能管理',
  PERMISSION_MANAGEMENT: '权限管理',
  SYSTEM_SETTING_MANAGEMENT: '系统设置管理',
  BUSINESS_MANAGEMENT: '业务管理',
  SHIP_MANAGEMENT: '船舶管理',
  SYSTEM_LOG: '系统日志',
  FILE_MANAGEMENT: '文件管理',
  EXPERIMENTAL_FUNCTION: '实验功能',
  AUDIT_LOGGING: '审计日志',
  REQUEST_LOGGING: '请求日志',
  DATA_CHANGE_LOGGING: '数据变更日志',
  WHITELIST_MANAGEMENT: '白名单及信用额度管理',
  MASTER_CONTRACT_MANAGEMENT: '主合同管理',
  MASTER_CONTRACT_CONFIRMTION: '主合同确认',
  PURCHASE_ORDER_MANAGEMENT: '采购确认单管理',
  SALES_ORDER_MANAGEMENT: '销售确认单',
  SHIPOIL_ORDER_MANAGEMENT: '船油销售单',
  TRADERS: '贸易公司',
  FREIGHTER_COMPANY: '货轮公司',
  BARGE_COMPANY: '驳船公司',
  PURCHASE_ORDER: '采购确认单',
  PURCHASE_ORDER_A: '采购确认单',
  PURCHASE_ORDER_CONFIRMTION: '采购确认单确认',
  PURCHASE_ORDER_CONFIRMTION_A: '采购确认单确认',
  SALES_ORDER_CONFIRMTION: '销售确认单确认',
  ARRIVE_OPERATION_DETAILS: '抵达操作细节',
  BUNKER_SALES_ORDER: '船油销售单',
  OIL_SUPPLY_COMPANY: '供油公司',
  FINANCING_APPLICATION_MANAGEMENT: '融资申请管理',
  FINANCING_APPLICATION_CONFIRMATION: '融资申请确认',
  BARGE_FILLING_PROGRAM_MANAGEMENT: '驳船装油计划',
  BARGE_DISTRIBUTION_OIL_PROGRAM: '驳船分油计划',
  IOT_BUNKER_LOADING_DATAS: '分油监控',
  FUNDING_AGENCY: '资金提供机构',
  INVOICE_MANAGEMENT: '发票开立',
  INVOICE_CONFIRMTION: '发票确认',
  INVOICE_APPLICATION: '发票申请及确认',
  RECEIPT_CONFIRMTION: '收款确认',
  PAYMENT_SLIP: '付款确认',
  PAYMENT_RECEIPT_SLIP: '收款确认',
  FINANCING_RECEIPT: '融资收款',
  SALES_APPLICATION_FORM: '销售申请',
  SALES_APPLICATION_FORM_AUDIT: '销售申请审核',
  ACCOUNTS_PAYABLE: '财政应付账款',
  ACCOUNTS_RECEIVABLE: '财政应收账款',
  FLOW_MANAGEMENT: '流程管理',
  OFFLINE_MODE: '线下模式',
  order: '订单',
  orderId: '订单ID',
  orderType: '订单类型',
  exWharf: 'Ex_wharf',
  relatedOrder: '上游订单',
  counterPartyEmail: '交易方邮箱',
  MENU_ACCOUNTS_RECEIVABLE: '应收账款',
  MENU_ACCOUNTS_RECEIVABLE_POSTED_LIST: '发布列表',
  MENU_ACCOUNTS_RECEIVABLE_INVOICE: '发票',
  MENU_ACCOUNTS_RECEIVABLE_NOTE: '贷记/借记单'
}

const businessAttachment = {
  CONTRACT: '合同',
  ORDER: '订单',
  INVOICE: '发票',
  COLLECTION_ORDER: '收款单',
  FINANCING_APPLICATION_ORDER: '融资申请单',
  SALES_APPLICATION_ORDER: '销售申请单',
  SALES_ORDER: '销售单',
  PAYMENT_ORDER: '付款单',
  FINANCING_CONTRACT: '融资合同',
  OIL_DISTRIBUTING_PLAN: '分油计划',
  LOADING_PLAN: '装油计划'
}

const users = {
  fullname: '姓名',
  job: '职位',
  jobTitle: '职位明细',
  technicalPost: '技术岗位',
  managementPost: '管理岗位',
  handleRoles: '配置角色',
  currentRoles: '当前角色',
  optional: '可选',
  selected: '已选',
  bound: '已绑定',
  binding: '绑定',
  unbinding: '解绑',
  confirm: '确认',
  okToBind: '确定进行绑定吗？',
  deleteSuccess: '删除成功！',
  roleBindingFailed: '角色绑定失败',
  selectDataFirst: '请先选择一条数据',
  singleSelection: '只能选择一条数据',
  gender: '性别',
  male: '男',
  female: '女'
}

const roles = {
  administrator: '系统管理员',
  operator: '操作员'
}

const masterContract = {
  masterContract: '主合同',
  masterContractInfo: '主合同信息',
  closeContract: '合同关闭',
  creditInfo: '信用信息',
  basicInfo: '基础信息',
  vesselInfo: '船舶信息',
  remarksInfo: '备注信息',
  contractNo: '合同编号',
  contractType: '合同类型',
  contractMode: '合同模式',
  pricingType: '定价类型',
  datetime: '日期时间',
  createDate: '创建日期',
  queryType: '查询类型',
  contractDate: '合同日期',
  termsPayable: '应付条款',
  saleTerms: '销售条款',
  pickUp: '取货',
  country: '国家',
  port: '港口',
  seller: '卖方',
  sellerInCharge: '卖方负责人',
  contacts: '联系方式',
  buyer: '买方',
  creditTerm: '信用期限',
  buyerInCharge: '买方负责人',
  creditLine: '信用额度',
  creditLineUse: '信用额度使用',
  creditLineSurplus: '信用额度剩余',
  totalContractValue: '合同总价值',
  laydaysStart: '船舶受载期开始',
  laydaysEnd: '船舶受载期结束',
  specs: '规格',
  pickUpQuantity: '取货量',
  maxPickupTimes: '最大取货次数',
  totalQuantity: '总数量',
  surplus: '剩余量',
  operatingTolerance: '操作公差',
  pickUpMin: '最小取货量',
  pickUpMax: '最大取货量',
  unit: '单位',
  gradesAndSpecs: '要求的等级和规格',
  minQuantity: '最小数量',
  maxQuantity: '最大数量',
  buyingPrice: '买价',
  sellingPrice: '卖价',
  totalPrice: '总价',
  downstream: '下查',
  remarks: '备注',
  buyerRemark: '买方备注',
  sellerRemark: '卖方备注',
  usageRecord: '合同执行记录',
  orderNo: '订单编号',
  pickupConditions: '取货条件',
  pickUpDate: '取货日期',
  oilName: '油品名称',
  pickUpValue: '取货价值',
  imoNotRequired: 'IMO号(非必填)',
  pickupNoticeDays: '取货通知天数',
  declareDate: '申报日期',
  sellerHandler: '卖方操作负责人'
}

const salesOrderManagement = {
  expectedArrivalInfo: '货轮预计抵达信息',
  thirdPartyInfo: '第三方信息',
  arrivalInfo: '抵达信息',
  salesConfirmation: '销售确认单',
  saleOrderNo: '销售单编号',
  purchaseNo: '采购单编号',
  saleDate: '销售日期',
  checkDate: '验证日期',
  agent: '代理商',
  supplier: '供应商',
  delivery: '交付',
  termsAndConditions: '一般条款条件',
  startTrader: '开始贸易商',
  endTrader: '结束贸易商',
  totalProfit: '总利润',
  estimatedTime: '预计抵达时间',
  estimatedHours: '预计时间(小时)',
  estimatedDateTime: '日期&时间',
  emailDatetime: '电子邮件日期时间',
  anchorage: '锚地',
  reporter: '报告人',
  arriveDetails: '驳船抵达操作细节',
  commodity: '商品',
  bargeName: '驳船名称',
  bargeInCharge: '驳船负责人',
  bargeContacts: '驳船联系方式',
  arriveDate: '抵达日期',
  arrive: '抵达',
  arriveShipSide: '抵达船边',
  hoseConnection: '软管连接',
  pumpOilStart: '泵油开始',
  pumpOilEnd: '泵油结束',
  hoseDisconnected: '软管断开',
  bearOff: '驶离',
  deliveryQuantityMT: '交付数量(公吨)',
  orderExecuteRecord: '订单执行记录',
  associatedPurchaseConfirmation: '已关联采购确认单',
  associatePurchaseConfirmation: '关联采购确认单',
  withoutApproval: '无需审批',
  resendMail: '重发邮件',
  resendMailSuccess: '重发邮件成功，请通知卖方查收邮件'
}

const shipoilOrderManagement = {
  shipoilOrderNo: '船油销售单编号',
  settlementCurrency: '结算单货币',
  totalAmount: '合计金额',
  associate: '关联',
  associateSalesConfirmation: '关联销售确认单',
  associatedSalesConfirmation: '已关联销售确认单'
}

const whiteListManagement = {
  whitelistCompany: '白名单公司',
  clientInformation: '客户信息',
  amountInformation: '额度信息',
  quotaChangeHistory: '额度变更历史记录',
  supplierName: '客户名称',
  enterpriseGroup: '集团',
  supplierAddress: '客户地址',
  supplierPic: '客户负责人',
  supplierContact: '客户联系方式',
  supplierEmail: '供油方邮箱',
  enterpriseEmail: '企业邮箱',
  personalEmail: '个人邮箱',
  email: '电子邮箱',
  creditLineAllocation: '信贷额度分配',
  currency: '币种',
  creditType: '信用类型',
  longestPaymentDays: '单笔最长账期',
  approvalDate: '信用审核通过日期',
  lastCreditAllocation: '最后的信用分配',
  creditExtensionDate: '信用延期日期',
  extendedApplicantPosition: '延期申请者职位',
  extendedApplicantName: '延期申请者姓名',
  nextReviewDate: '下次审核日期',
  balance: '信用余额',
  availableCredit: '可用余额',
  quotaApprovalInfo: '额度审批信息',
  assets: '资产',
  shipName: '船舶名称',
  imo: 'IMO号',
  grt: '总吨位',
  builtYear: '建造年份',
  vesselAge: '船龄',
  fuelType: '燃油种类',
  vesselSize: '船型',
  hullClass: '船舶结构',
  majorApproved: '大型企业检验',
  ownership: '所有权',
  gradesSupplied: '供应等级',
  sulphur: '硫',
  grade: '等级',
  pastStems: '加油历史',
  stemNumber: '加油编号',
  deliveryDate: '交付日期',
  creditTerms: '信用期限',
  grades: '等级',
  quantity: '数量',
  buyPrice: '买价',
  amount: '金额',
  paymentDate: '支付日期',
  agingReport: '帐龄分析表',
  uploadSupportFiles: '上传支持文件',
  procStatus: '审批状态',
  approvalNotBegun: '未开始审批',
  EXECUTE: '审批中',
  FINISHED: '审批完成',
  amountCreationDate: '创建额度日期',
  getApprovedDate: '审核通过日期',
  quotaType: '额度类型',
  creditAmount: '信贷金额',
  creator: '创建人',
  quotaStartDate: '额度开始日',
  quotaHistoryDay: '额度历史日',
  accendant: '维护人'
}

const purchaseOrdersManagement = {
  purchaseConfirmation: '采购确认单',
  orderNumber: '订单编号',
  signedDate: '签署日期',
  stemDate: '加油日期',
  mode: '模式',
  queryType: '查询类型',
  orderMode: '订单模式',
  creditTerms: '信用期限',
  payableClause: '应付条款',
  creditInformation: '信用信息',
  contractNumber: '合同编号',
  buyer: '买方',
  buyerInCharge: '买方负责人',
  buyerContact: '买方联系方式',
  traderInCharge: '贸易商负责人',
  traderContact: '贸易商联系方式',
  vesselName: '船舶名称',
  imo: 'IMO号',
  country: '国家',
  callPort: '停靠港口',
  laycanStartDate: '船舶受载开始日期',
  laycanEndDate: '船舶受载结束日期',
  laydaysStart: '船舶受载开始日期',
  laydaysEnd: '船舶受载结束日期',
  agent: '代理商',
  agentContact: '代理商联系方式',
  surveyor: '公证人企业',
  surveyorContact: '公证人企业联系方式',
  notary: '公证人',
  supplier: '供应商',
  deliveryMode: '交付方式',
  gtcs: '一般条款条件',
  businessMode: '业务模式',
  residualCreditLimit: '剩余信用额度',
  mainContractNo: '主合同编号',
  rfqst: '可修改最终数量的开始日期',
  rfqet: '可修改最终数量的结束日期',
  gradesSpecs: '要求的等级和规格',
  grade: '等级',
  sulfur: '硫',
  specification: '规格',
  minimumQuantity: '最小数量',
  maximumQuantity: '最大数量',
  buyPrice: '买价',
  finalQuantity: '最终数量',
  declaredDate: '申报日期',
  changeRecord: '贸易商设定最终数量/申报日期的变更记录',
  changeDate: '变更日期',
  changeUser: '变更用户',
  note: '备注',
  selectionMasterContract: '选择主合同',
  associatedMasterContract: '已关联的主合同',
  CASH_MODE: '现金模式',
  CREDIT_MODE: '信用模式',
  changeNumber: '变更数量',
  inputDateRange: '填写日期范围',
  buyerSellerInfo: '买卖双方信息',
  bargeInfo: '驳船信息',
  confirmQuantity: '确认数量',
  globalValue: '总价',
  floating: '+/-',
  sellerContact: '卖方联系方式',
  checkUp: '上查',
  checkDown: '下查',
  vesselManager: '当前订单舶船管理员',
  firstConfirm: '初次确认',
  finalConfirm: '最终确认'

}

const financingApplicationManagement = {
  newFinancingApplicationForm: '融资申请单',
  financingApplicationNo: '融资申请编号',
  createDate: '创建日期',
  buyer: '买方',
  seller: '卖方',
  vesselName: '船舶名称',
  beginningTrader: '开始贸易商',
  endingTrader: '结束贸易商',
  bargeName: '驳船名称',
  bargeInCharge: '驳船负责人',
  country: '国家',
  callPort: '停靠港口',
  status: '状态',
  directionalFinancing: '是否是定向融资',
  prospectiveFinancialInstitution: '意向金融机构',
  tradeTotalAmount: '贸易总金额',
  financingType: '融资类型',
  intendedFinancingAmount: '意向融资金额',
  annualRate: '意向融资年利率%',
  financePaymentDays: '融资账期(天)',
  expirationDate: '融资申请截止时间',
  financingTerms: '融资条款',
  financingAmount: '融资金额',
  finalFinancingAmount: '最终融资金额',
  interest: '利息',
  durationDays: '持续时间',
  guarantee: '保证',
  dailyInterest: '日利息',
  totalInterest: '合计利息',
  lossProfitPerTon: '每吨利润损失',
  associatedSalesConfirmation: '关联销售确认单',
  financingSummary: '融资意向概述',
  oilSalesConfirmation: '供油方的销售确认单',
  traderSalesConfirmation: '贸易商的销售确认单',
  traderPurchaseConfirmation: '贸易商的采购确认单',
  makeOverNotification: '货权转让通知'
}

const fillingProgramManagement = {
  bargeFillingProgram: '驳船装油计划',
  bargeFillingProgramForm: '驳船装油计划单',
  newBargeFillingProgram: '新增驳船装油计划',
  number: '编号',
  fillingDateTime: '装油日期及时间',
  saleTerms: '销售条款',
  loader: '装货方',
  traderInChargeContacts: '联系方式',
  sellerOperationsPic: '卖方操作负责人',
  sellerOperationsPicContacts: '联系方式',
  termsConditions: '条款及条件',
  supplyDays: '供油天数',
  loadingOperationDetails: '装载操作细节',
  assignBarge: '指定驳船',
  nominatedQuantity: '指定数量',
  loadingQuantity: '装载数量',
  nominationTendered: '驳船指定人',
  alongsideTime: '抵达船边时间',
  hoseConnectTime: '软管连接时间',
  pumpStartTime: '泵油开始时间',
  pumpStopTime: '泵油结束时间',
  castOffTime: '驶离时间',
  internalRemark: '内部备注',
  // 6.3驳船装油确认单
  bargeFillingConfirmation: '驳船装油确认单',
  BLNo: '提名编号',
  shipper: '托运人',
  vessel: '船舶',
  customLicenseNo: '海关编码',
  destination: '目的地',
  metricTon: '公吨',
  customsLicenseNo: '海关许可证编号',
  customsDocumentNo: '海关文件编号',
  berthNo: '泊位号',
  products: '产品',
  shipSealNo: '船舶编号',
  tankNo: '罐号',
  observationTemperature: '观察温度c',
  density15C: '15摄氏度密度',
  totalObservedVolume: '观察体积（KL）',
  totalVolume15C: '15C时的密度',
  VolumeInBbl60F: '15摄氏度的净容量（KL）',
  quantityInMT: '公吨',
  USBbls60F: 'US Bbls@ 60F',
  // 5.1原型修改后字段
  oilLoadingPlanNo: '装油计划编号',
  oilLoadingOrderNo: '装油单据编号',
  oilSupplier: '供油方',
  oilSupplierInCharge: '供油方负责人',
  oilDepotAddress: '油库地点',
  bargeNo: '驳号',
  oilLoadingInfo: '装油信息',
  fillingDate: '装油日期',
  actualLoadingQuantity: '实际装载量',
  measuringRange: '测量范围',
  pumpStatus: '泵状态',
  valveStatus: '阀状态',
  stop: '停止',
  close: '关闭',
  time: '时间',
  cargoOilPumpTachometer: '货油泵转速表',
  pleaseSelectBarge: '请选择驳船',
  percent: '百分比',
  level: '液位',
  temp: '温度',
  interval: '间隔',
  update: '更新',
  updatedBy: '更新人',
  preUpdateInfo: '更新前的信息',
  updatedInformation: '更新后的信息',
  // 质量流量计数据
  massFlowRate: '质量流量',
  density: '密度',
  temperature: '温度',
  massTotal: '质量累计',
  massInventory: '质量总量',
  volumeFlowRate: '体积流量',
  liveZero: '活零点',
  driveGain: '驱动增益',
  rawTubeFrequency: '原始管频率',
  rightPickoffVoltage: '右侧电压检测',
  leftPickoffVoltage: '左侧电压检测',
  flowSignalOffsetAtZeroFlow: '零流量点的流量信号偏移量',
  volumeTotal: '体积总量',
  APICorrectedVolumeTotal: 'API标准校正体积总量',
  tempAPICorrectedDensity: '临时API校正的密度',
  APIAvgCorrectedDensity: 'API平均校正密度',
  APIAvgCorrectedTemperature: 'API平均校正温度',
  currentCorrection: '电流校正',
  discreteInput1Status: '离散输入1状态',
  maxAerationLimit: '最大充气限度',
  externalPressureInput: '外部压力输入',
  fieldVerificationZero: '现场校验零点',
  deltaT: '测量之间的时间差',
  statusReports: '状态报告'
}

const invoiceManagement = {
  invoice: '发票',
  refuelingReceipt: '加油小票',
  invoiceApplication: '发票申请',
  supplierEmail: '供油方邮箱',
  invoiceNo: '发票编号',
  invoiceDate: '发票日期',
  dueDate: '使用日期',
  invoiceStatus: '发票状态',
  returnFundAmount: '回款金额',
  invoiceTrader: '发票（贸易）',
  companyAddress: '单位地址',
  companyRegistrationNo: '公司注册编号',
  taxInvoiceNo: '税务发票编号',
  companyPhone: '单位电话',
  email: '电子邮箱',
  attn: '联系人',
  terms: '条款',
  settlementCurrency: '结算货币',
  taxRate: '税率',
  commodityDetails: '商品明细',
  supplyDate: '供应日期',
  QTYMT: '数量/公吨',
  unitPrice: '单价',
  GST: '销售税',
  taxAmount: '合计',
  invoiceInfo: '发票信息',
  exchangeRate: '汇率',
  receipt: '收款',
  paymentReceiptRecord: '收款记录',
  receiptDate: '收款日期',
  receiptAmount: '收款金额',
  CREATED: '已创建',
  TRADER_CONFIRMED: '贸易商已确认',
  TRADERS_CONFIRMED: '贸易商已确认',
  FUND_PROVIDER_CONFIRMED: '资金方已确认',
  FUNDER_CONFIRMED: '资金方待确认',
  PENDING_COLLECTION: '待收款',
  RECEIVED_MONEY: '已收款',
  NO_CONFIRMED: '未确认',
  CONFIRMED: '已确认',
  Confirmed: '已确认',
  CARGO_CONFIRMED: '货轮公司已确认',
  invoiceConfirmTip: '此操作将更新发票状态，是否继续？',
  associatedInvoice: '已关联发票',
  associateInvoice: '关联发票'
}

const paymentSlip = {
  paymentSlip: '付款单',
  paymentNo: '付款单编号',
  payer: '付款方',
  payee: '收款方',
  paymentReceiptDate: '付款日期',
  totalPaymentReceipt: '合计金额',
  paymentReceiptSlip: '收款单',
  receiptNo: '收款单编号',
  receiptPaymentDate: '收款日期',
  associatedPaymentSlip: '已关联付款单',
  associatePaymentSlip: '关联付款单',
  associatedFinancingApplication: '已关联融资申请',
  associateFinancingApplication: '关联融资申请',
  payment_CREATED: '已创建',
  payment_CONFIRM: '已确认',
  isConfirmTip: '是否确认？'
}

const bunkerSalesOrder = {
  bunkerSales: '船油销售单',
  bunkerSaleNo: '船油销售单编号',
  mode: '模式',
  businessModeSelect: '业务模式选择',
  totalAmount: '合计金额',
  FINANCING_NOTICE: '融资-通知',
  FINANCING_NON_NOTICE: '融资-非通知',
  NON_FINANCING: '非融资',
  associatedSalesApplication: '已关联船油销售申请',
  associateSalesApplication: '关联船油销售申请',
  associatedBunkerSales: '已关联船油销售单',
  associateBunkerSales: '关联船油销售单'
}

const shipOilSalesManagement = {
  bunkerSalesApplication: '船油销售申请',
  modeSelection: '模式选择',
  salesApplicationNo: '销售申请单编号',
  agreeSwitchOnBargePump: '同意打开驳船泵阀'
}
const salesApplicationForm = {
  salesRequisitionNo: '销售申请单编号',
  proposer: '申请人',
  financingInstitution: '金融机构',
  applicationDate: '申请日期',
  oils: '油类'
}

const exWharf = {
  createNewOrder: '新增订单',
  linkContract: '关联合同',
  linkOrder: '关联订单',
  purchaseContract: '采购合同',
  salesContract: '销售合同',
  purchaseAndSalesContract: '采购和销售合同',
  saveAsDraft: '保存为草稿',
  buyer: '买方交易员',
  seller: '卖方交易员'
}

const priceAndFormula = {
  manage : '管理'
}

export default {
  ...cn,
  ...enterpriseRegister,
  ...menus,
  ...users,
  ...roles,
  ...masterContract,
  ...salesOrderManagement,
  ...shipoilOrderManagement,
  ...whiteListManagement,
  ...purchaseOrdersManagement,
  ...financingApplicationManagement,
  ...fillingProgramManagement,
  ...invoiceManagement,
  ...paymentSlip,
  ...bunkerSalesOrder,
  ...shipOilSalesManagement,
  ...salesApplicationForm,
  ...exWharf,
  ...businessAttachment,
  ...priceAndFormula
}
